import React from 'react';

import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import IntegratedIllustration from '_images/svg/illustrations/Integrated.svg';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';

import { ContainerGraphicWide } from './common/Section';

/**
 * API section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const API = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <IntegratedIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>API</H2>

      <p>
        Take advantage of Pricematepay Gateway API to easily integrate payment functionality into
        your own website or software.
      </p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/docs/api"
          target="_blank"
          bgcolor={colours.redDamask}
        >
          Read Documentation
        </ExternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default API;
