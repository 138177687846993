import React from 'react';

import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';
import VirtualTerminalsIllustration from '_images/svg/illustrations/VirtualTerminals.svg';

import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';

import { ContainerGraphicTall } from './common/Section';

/**
 * Virtual Terminal section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const VirtualTerminal = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicTall>
      <VirtualTerminalsIllustration />
    </ContainerGraphicTall>

    <div>
      <H2>Virtual Terminal</H2>

      <p>
        Our virtual terminal gives your business the freedom to create charges of any size,
        anywhere, at any time, no invoices required.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/services/" bgcolor={colours.redDamask}>
          More about Virtual Terminal
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default VirtualTerminal;
