import React from 'react';

import {
  ContainerTwoColumn,
  ContainerGraphic,
  ContainerText,
  ContainerButtons,
  H1,
} from '_templates/LandingSection';
import CoffeeShopIllustration from '_images/svg/illustrations/CoffeeShop.svg';
import colours from '_theming/colours';
import ExternalLinkButton from '_components/ExternalLinkButton';
import InternalLinkButton from '_components/InternalLinkButton';

/**
 * The "Accept Payments For Less" section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const AcceptPaymentsForLess = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphic>
      <CoffeeShopIllustration />
    </ContainerGraphic>

    <ContainerText>
      <H1>Accept payments for less</H1>

      <p>
        Pricematepay makes it easier for all businesses to accept card and digital payments, all
        while minimizing the processing fees that cut into your margins.
      </p>

      <p>The technology you need and the prices you deserve; that's the Pricematepay promise.</p>

      <ContainerButtons>
        <ExternalLinkButton
          href="https://gateway.pricematepay.com/signup"
          bgcolor={colours.redDamask}
          target="_blank"
        >
          Get Started Now
        </ExternalLinkButton>

        <InternalLinkButton to="/contact/" bgcolor={colours.linkWater}>
          Get a Quote
        </InternalLinkButton>
      </ContainerButtons>
    </ContainerText>
  </ContainerTwoColumn>
);

export default AcceptPaymentsForLess;
