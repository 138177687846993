import React from 'react';

import BillingIllustration from '_images/svg/illustrations/Billing.svg';
import colours from '_theming/colours';
import InternalLinkButton from '_components/InternalLinkButton';
import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';

import { ContainerGraphicWide } from './common/Section';

/**
 * Billing section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const Billing = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicWide>
      <BillingIllustration />
    </ContainerGraphicWide>

    <div>
      <H2>Billing</H2>

      <p>
        With billing solutions like hosted invoices and recurring subscriptions, you get paid on
        your terms, on time, anytime.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/billing/" bgcolor={colours.redDamask}>
          More about Billing
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default Billing;
