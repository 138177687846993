import React from 'react';

import colours from '_theming/colours';
import { ContainerTwoColumn, ContainerButtons, H2 } from '_templates/Sections';
import InternalLinkButton from '_components/InternalLinkButton';
import POSTerminalsIllustration from '_images/svg/illustrations/POSTerminals.svg';

import { ContainerGraphicTall } from './common/Section';

/**
 * Terminals section.
 *
 * @param {object} props Component props.
 * @param {string} props.flexDirection Direction of the flex content.
 * @returns {React.Component} Section.
 */
const Terminals = ({ flexDirection }) => (
  <ContainerTwoColumn flexDirection={flexDirection}>
    <ContainerGraphicTall>
      <POSTerminalsIllustration />
    </ContainerGraphicTall>

    <div>
      <H2>Terminals</H2>

      <p>
        With a variety of powerful handheld terminals, we help make in-person purchases for your
        business a breeze.
      </p>

      <ContainerButtons>
        <InternalLinkButton to="/services/" bgcolor={colours.redDamask}>
          More about Merchant Services
        </InternalLinkButton>
      </ContainerButtons>
    </div>
  </ContainerTwoColumn>
);

export default Terminals;
