import styled from 'styled-components';

/**
 * Container for graphics with a wide aspect ratio.
 */
const ContainerGraphicWide = styled.div`
  svg {
    width: 100%;
    max-width: 450px;

    height: auto;
  }

  text-align: center;
`;

/**
 * Container for graphics with a tall aspect ratio.
 */
const ContainerGraphicTall = styled.div`
  svg {
    height: 100%;
    max-height: 350px;

    width: auto;
  }

  text-align: center;
`;

export { ContainerGraphicWide, ContainerGraphicTall };
